label {
  display: inline-block;
  margin: 15px;
  font-size: 20px;
}

input {
  display: inline-block;
  font-size: 18px;
}

header h2 { 
  color: white;
  font-size: 24px;
}

header h3 { 
  color: white;
  font-size: 18px;
  opacity: 0.8;
  font-family: Arial, Helvetica, sans-serif;
}

.navbar .row {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 0.5rem;
}

.t-center {
  text-align: center;
}

.t-justify {
  text-align: justify;
}


.balance-checker .row {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 0.5rem;
}